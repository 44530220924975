/** common **/
[class^="icon--"], [class*=" icon--"] {
  font-family: 'fot-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon--right-arrow:before {
  content: "\e910";
  color: #fff; }

.icon--satisfying-pay:before {
  content: "\e90c"; }

.icon--remote-work:before {
  content: "\e90d"; }

.icon--advanced-technology:before {
  content: "\e90e"; }

.icon--training:before {
  content: "\e90f"; }

.icon--time-off:before {
  content: "\e917"; }

.icon--work-with-the-leader:before {
  content: "\e918"; }

.icon--add:before {
  content: "\e90b"; }

.icon--categories:before {
  content: "\e901"; }

.icon--search:before {
  content: "\e902"; }

.icon--small_business:before {
  content: "\e903"; }

.icon--fb:before {
  content: "\e904"; }

.icon--gp:before {
  content: "\e905"; }

.icon--in:before {
  content: "\e906"; }

.icon--tm:before {
  content: "\e900"; }

.icon--tw:before {
  content: "\e907"; }

.icon--yt:before {
  content: "\e908"; }

.icon--top_soft:before {
  content: "\e909"; }

.icon--pi:before {
  content: "\e90a"; }

.icon-drop-down-arrow:before {
  content: "\e911"; }

.icon--question:before {
  content: "\e912"; }

.icon--toc:before {
  content: "\e913"; }

.icon--star:before {
  content: "\e914";
  color: #fff; }

.icon--window:before {
  content: "\e915";
  color: #fff; }

.icon--checked:before {
  content: "\e916"; }

.icon--arrow-down:before {
  content: "\e919"; }

.icon--close:before {
  content: "\e91a"; }

.icon--customer:before {
  content: "\e91b"; }

.icon--deploy:before {
  content: "\e91c"; }

.icon--devices:before {
  content: "\e91d"; }

.icon--our-score:before {
  content: "\e91e"; }

.icon--pricing:before {
  content: "\e91f"; }

.icon--pricing-model:before {
  content: "\e920"; }

.icon--user-sat:before {
  content: "\e921"; }

.icon--basic-questions:before {
  content: "\e922"; }

.icon--evaluation:before {
  content: "\e923"; }

.icon--quote:before {
  content: "\e924"; }

.icon--reply:before {
  content: "\e925"; }

.icon--verified:before {
  content: "\e926"; }

.icon--network:before {
  content: "\e927"; }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  display: block;
  font-family: Lato, "Trebuchet MS", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0; }

a {
  text-decoration: none;
  color: #005691; }

p.big {
  font-size: 42px !important;
  line-height: 34px !important;
  font-weight: 700; }

p.medium {
  font-size: 28px !important;
  line-height: 26px !important;
  font-weight: 700; }

button {
  font-family: Lato, "Trebuchet MS", Arial, sans-serif;
  font-size: 14px; }
  button.btn {
    border: none; }

.main-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto; }
  .main-container--bigger {
    max-width: 1600px; }

.pull-left {
  float: left !important; }

.pull-right {
  float: right !important; }

.clearfix::after {
  display: block;
  content: "";
  clear: both; }

.no-padding {
  padding: 0 !important; }

.btn {
  text-decoration: none;
  background: #005691;
  line-height: 19px;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 13px;
  color: #fff;
  border-radius: 4px;
  -webkit-transition: .4s ease-out;
  -o-transition: .4s ease-out;
  transition: .4s ease-out;
  cursor: pointer; }
  .btn--blue-hover:hover {
    background: #018eef; }
  .btn--listed, .btn--compare {
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    padding-left: 26px;
    padding-right: 26px; }
    .btn--listed:hover, .btn--compare:hover {
      background: #018eef; }
    .btn--listed .icon, .btn--compare .icon {
      margin-right: 10px;
      margin-left: -5px; }
  .btn--listed {
    position: relative;
    padding-left: 47px; }
    .btn--listed .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 26px; }
  .btn--orange {
    background: #ffab00; }
    .btn--orange:hover {
      background: #f9dc10 !important; }
  .btn--apply {
    font-size: 14px;
    padding-left: 60px;
    padding-right: 60px;
    font-weight: 700;
    margin: 0 auto; }
    .btn--apply:hover {
      -webkit-filter: brightness(120%);
              filter: brightness(120%); }
  .btn--top {
    position: fixed;
    bottom: 10px;
    right: 15px;
    padding: 5px 8px;
    font-weight: 700;
    z-index: 99;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: none; }
    .btn--top .icon {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg);
      margin: 7px 0 2px;
      display: block;
      font-size: 16px; }
    .btn--top:hover {
      background: #018eef; }
  .btn--twitter {
    background: #1da1f2;
    padding-left: 64px;
    padding-right: 64px;
    position: relative; }
    .btn--twitter:hover {
      background: #3fc3f5; }
    .btn--twitter .icon {
      width: 17px;
      height: 17px;
      position: absolute;
      left: 30px;
      top: 13px;
      background: url(/media/themes/fot/dist/img/svg/twitter_white.svg); }

.blue {
  color: #005691 !important; }

.col {
  float: left; }
  .col--8 {
    width: 8.333%; }
  .col--10 {
    width: 10%; }
  .col--16 {
    width: 16.666%; }
  .col--20 {
    width: 20%; }
  .col--25 {
    width: 25%; }
  .col--30 {
    width: 30%; }
  .col--33 {
    width: 33.333%; }
  .col--40 {
    width: 40%; }
  .col--50 {
    width: 50%; }
  .col--60 {
    width: 60%; }
  .col--66 {
    width: 66.666%; }
  .col--70 {
    width: 70%; }
  .col--75 {
    width: 75%; }

.full-width {
  width: 100% !important; }

.sm-show {
  display: none; }

.mb-hide {
  display: block; }

.mb-show {
  display: none; }

.mini-show {
  display: none !important; }

img {
  max-width: 100%; }

.fot-blue-header {
  color: #fff;
  line-height: 48px;
  text-align: center;
  padding: 15px;
  background: url(/media/themes/fot/dist/img/fot-header.png) #12547b;
  width: 100%; }
  .fot-blue-header h3 {
    font-size: 42px;
    font-weight: 400;
    margin: 0; }

.flex-fixer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.center {
  text-align: center; }

.front-page {
  color: #fff; }

.bg-fixer {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: .85;
  background: #fff; }
  .bg-fixer--left {
    left: calc(-100% + (50% - 600px)); }
  .bg-fixer--right {
    right: calc(-100% + (50% - 600px)); }

.img-radius {
  border-radius: 50%; }

.hidden {
  display: none !important;
  visibility: hidden !important; }

.fot-question,
.cloudrank {
  border: 2px solid #005691;
  padding: 35px 95px;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin: 15px 0;
  position: relative;
  overflow: hidden; }
  .fot-question:before,
  .cloudrank:before {
    content: '';
    background: url(/media/themes/fot/dist/img/svg/question_mark_icon.svg);
    background-size: cover;
    position: absolute;
    left: 32px;
    top: 34px;
    width: 39px;
    height: 45px; }
  .fot-question:after,
  .cloudrank:after {
    content: '';
    position: absolute;
    background-image: url(/media/themes/fot/dist/img/que-bg.png);
    background-repeat: no-repeat;
    width: 166px;
    height: 225px;
    right: 0;
    bottom: 0; }
  .fot-question__text, .fot-question__header,
  .cloudrank__text,
  .cloudrank__header {
    font-size: 22px;
    font-weight: 900;
    color: #005691;
    border-bottom: 1px solid #ccc;
    padding: 0 0 20px;
    margin: 0 0 20px;
    position: relative;
    z-index: 9; }
    .fot-question__text p, .fot-question__header p,
    .cloudrank__text p,
    .cloudrank__header p {
      font-size: 22px !important;
      margin: 0 !important;
      line-height: 24px !important;
      position: relative;
      z-index: 9; }
  .fot-question__answer, .fot-question__content,
  .cloudrank__answer,
  .cloudrank__content {
    position: relative;
    z-index: 9;
    text-align: justify; }

.caption {
  text-transform: capitalize !important; }

.fot-big-image {
  text-align: center; }
  .fot-big-image .btn {
    background: #ffab00; }
    .fot-big-image .btn:hover {
      background: #f9dc10 !important; }
  .fot-big-image__img {
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
    -webkit-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s; }
    .fot-big-image__img:after {
      content: '';
      position: absolute;
      top: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 116, 12, 0)), to(white));
      background: -o-linear-gradient(top, rgba(255, 116, 12, 0) 0%, white 100%);
      background: linear-gradient(180deg, rgba(255, 116, 12, 0) 0%, white 100%); }
  .fot-big-image.active .fot-big-image__img {
    -webkit-transition: max-height 5s;
    -o-transition: max-height 5s;
    transition: max-height 5s;
    max-height: 99999px !important; }
    .fot-big-image.active .fot-big-image__img:after {
      display: none; }

.el-sliding {
  overflow: hidden; }

.fo-button-get-href {
  cursor: pointer; }
  .fo-button-get-href * {
    pointer-events: none; }

.sk {
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #ddd), color-stop(50%, #eee), to(#ddd));
  background: -o-linear-gradient(left, #ddd 0, #eee 50%, #ddd);
  background: linear-gradient(90deg, #ddd 0, #eee 50%, #ddd);
  -webkit-animation-name: placeholder;
          animation-name: placeholder;
  -webkit-animation-duration: 3.5s;
          animation-duration: 3.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  border-radius: 4px; }

@-webkit-keyframes placeholder {
  0% {
    background-position-x: 0; }
  100% {
    background-position-x: 800px; } }

@keyframes placeholder {
  0% {
    background-position-x: 0; }
  100% {
    background-position-x: 800px; } }

/* google fonts https://fonts.googleapis.com/css?family=Lato:300,400,700,900
 * converted to local using https://google-webfonts-helper.herokuapp.com/fonts/lato?subsets=latin-ext,latin
 */
/* lato-300 - latin_latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/lato-v14-latin_latin-ext-300.eot");
  /* IE9 Compat Modes */
  src: local("Lato Light"), local("Lato-Light"), url("../../fonts/lato-v14-latin_latin-ext-300.eot?#iefix") format("embedded-opentype"), url("../../fonts/lato-v14-latin_latin-ext-300.woff2") format("woff2"), url("../../fonts/lato-v14-latin_latin-ext-300.woff") format("woff"), url("../../fonts/lato-v14-latin_latin-ext-300.ttf") format("truetype"), url("../../fonts/lato-v14-latin_latin-ext-300.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-regular - latin_latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/lato-v14-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"), url("../../fonts/lato-v14-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/lato-v14-latin_latin-ext-regular.woff2") format("woff2"), url("../../fonts/lato-v14-latin_latin-ext-regular.woff") format("woff"), url("../../fonts/lato-v14-latin_latin-ext-regular.ttf") format("truetype"), url("../../fonts/lato-v14-latin_latin-ext-regular.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-700 - latin_latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/lato-v14-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../../fonts/lato-v14-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("../../fonts/lato-v14-latin_latin-ext-700.woff2") format("woff2"), url("../../fonts/lato-v14-latin_latin-ext-700.woff") format("woff"), url("../../fonts/lato-v14-latin_latin-ext-700.ttf") format("truetype"), url("../../fonts/lato-v14-latin_latin-ext-700.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-900 - latin_latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../fonts/lato-v14-latin_latin-ext-900.eot");
  /* IE9 Compat Modes */
  src: local("Lato Black"), local("Lato-Black"), url("../../fonts/lato-v14-latin_latin-ext-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/lato-v14-latin_latin-ext-900.woff2") format("woff2"), url("../../fonts/lato-v14-latin_latin-ext-900.woff") format("woff"), url("../../fonts/lato-v14-latin_latin-ext-900.ttf") format("truetype"), url("../../fonts/lato-v14-latin_latin-ext-900.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* FO icons as font */
@font-face {
  font-family: 'fot-font';
  src: url("../../fonts/fot-font.eot?240919");
  src: url("../../fonts/fot-font.eot?240919#iefix") format("embedded-opentype"), url("../../fonts/fot-font.ttf?240919") format("truetype"), url("../../fonts/fot-font.woff?240919") format("woff"), url("../../fonts/fot-font.svg?240919#fot-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/** partials **/
.top-bar {
  background: #005691;
  padding: 12px 0;
  color: #fff;
  font-family: Lato, "Trebuchet MS", Verdana, sans-serif; }
  .top-bar .main-container {
    overflow: hidden; }
  .top-bar.overflow .main-container {
    overflow: initial; }
  .top-bar__logo {
    float: left;
    margin: 0 0 0 30px;
    position: relative;
    width: 250px;
    min-height: 1px; }
    .top-bar__logo img {
      position: absolute;
      top: -37px;
      left: -47px; }
  .top-bar a {
    color: #fff; }
  .top-bar__toggle {
    width: 37px;
    font-size: 12px;
    float: left;
    cursor: pointer;
    height: 46px; }
    .top-bar__toggle span {
      width: 100%;
      height: 4px;
      display: block;
      margin-bottom: 6px;
      background: #fff;
      position: relative;
      -webkit-transition: .4s linear;
      -o-transition: .4s linear;
      transition: .4s linear; }
      .top-bar__toggle span:last-of-type {
        margin-bottom: 7px; }
    .top-bar__toggle.opened span {
      background: #fff; }
      .top-bar__toggle.opened span:nth-child(1) {
        top: 14px;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg); }
      .top-bar__toggle.opened span:nth-child(2) {
        width: 0%;
        opacity: 0; }
      .top-bar__toggle.opened span:nth-child(3) {
        top: -6px;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg); }
  .top-bar__search {
    float: left;
    position: relative;
    margin-left: -15px; }
    .top-bar__search input {
      border-radius: 4px;
      border: 1px solid #4d89b2;
      background: #1a679c;
      padding: 10px 40px 10px 15px;
      line-height: 25px;
      font-family: Arial, Verdana, sans-serif;
      font-size: 15px;
      font-weight: 300;
      color: #fff;
      width: 265px;
      -webkit-transition: .4s ease-out;
      -o-transition: .4s ease-out;
      transition: .4s ease-out; }
      .top-bar__search input::-webkit-input-placeholder {
        color: #fff; }
      .top-bar__search input::-moz-placeholder {
        color: #fff; }
      .top-bar__search input:-ms-input-placeholder {
        color: #fff; }
      .top-bar__search input:-moz-placeholder {
        color: #fff; }
    .top-bar__search .search-loading {
      background: url(/media/themes/fot/dist/img/svg/spinner-white.svg);
      position: absolute;
      right: 15px;
      width: 32px;
      height: 32px;
      top: 7px;
      display: none; }
    .top-bar__search__btn {
      position: absolute;
      top: calc(50% - 12px);
      right: 15px;
      background: 0 0;
      border: none;
      font-size: 22px;
      color: #fff;
      width: 22px;
      height: 22px;
      padding: 0;
      cursor: pointer; }
  .top-bar__nav {
    font-size: 16px;
    font-weight: 700;
    float: left;
    padding: 13px 0;
    line-height: 20px; }
    .top-bar__nav ul {
      list-style: none;
      margin: 0; }
    .top-bar__nav a:not(.clearfix),
    .top-bar__nav span:not(.clearfix) {
      padding-bottom: 8px;
      cursor: pointer; }
    .top-bar__nav a:not(.icon):hover,
    .top-bar__nav span:not(.icon):hover {
      border-bottom: 2px solid #ffab00;
      text-decoration: none; }
    .top-bar__nav .icon {
      font-size: 28px;
      position: absolute;
      left: -35px;
      top: -5px;
      cursor: default; }
  .top-bar__nav-el {
    display: inline-block;
    margin-right: 70px;
    position: relative; }
    .top-bar__nav-el:nth-child(1) {
      width: 187px; }
    .top-bar__nav-el:nth-child(2) {
      width: 155px; }
    .top-bar__nav-el:nth-last-of-type(2), .top-bar__nav-el:last-of-type {
      margin-right: 35px; }
    .top-bar__nav-el.menu-dropdown:after {
      content: "\e911";
      position: absolute;
      top: 6px;
      right: -13px;
      font-family: fot-font;
      font-size: 7px;
      line-height: 7px; }

.categories-menu {
  display: none;
  background: #126299;
  font-size: 16px;
  font-weight: 700;
  line-height: 42px;
  color: #fff;
  position: absolute;
  width: 100%;
  z-index: 999; }
  .categories-menu a {
    color: #fff; }
    .categories-menu a:hover {
      color: #ffab00; }
  .categories-menu__el {
    border-bottom: 1px solid #4181ad;
    width: 30%;
    float: left;
    margin: 0 1.66%;
    color: #96d4ff;
    display: block;
    position: relative; }
    .categories-menu__el:before {
      content: "\e910";
      font-family: 'fot-font';
      font-size: 12px;
      color: #fff;
      position: absolute;
      left: -19px;
      -webkit-transition: .4s linear;
      -o-transition: .4s linear;
      transition: .4s linear; }
    .categories-menu__el.opened:before {
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg); }
  .categories-menu__ul {
    padding: 15px 0 30px;
    margin: 0;
    list-style: none;
    clear: both;
    overflow: hidden; }
  .categories-menu__submenu {
    display: none;
    list-style: disc; }
  .categories-menu__submenu-el {
    width: 100%;
    font-weight: 300; }
    .categories-menu__submenu-el a {
      color: #96d4ff; }
  .categories-menu__show-all {
    background: #005691;
    text-align: center;
    line-height: 20px;
    padding: 15px 0;
    cursor: pointer;
    -webkit-transition: .1s linear;
    -o-transition: .1s linear;
    transition: .1s linear; }
    .categories-menu__show-all:hover {
      background: #126299;
      color: #ffab00; }
    .categories-menu__show-all.clicked .all {
      display: none; }
    .categories-menu__show-all.clicked .popular {
      display: inline-block; }
    .categories-menu__show-all .popular {
      display: none; }

.mobile-nav {
  display: none;
  width: 100%; }
  .mobile-nav ul {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .mobile-nav li {
    margin: 0; }

.footer {
  background: #f4f4f4;
  color: #333;
  padding: 65px 0 30px;
  font-size: 16px; }
  .footer__title {
    font-size: 38px;
    font-weight: 300;
    margin: 0 0 20px; }
    .footer__title span {
      font-weight: 700; }
  .footer a {
    color: #000; }
  .footer ul {
    padding: 0;
    list-style: none;
    margin-top: 15px; }
    .footer ul a {
      color: #333; }
      .footer ul a:hover {
        color: #000; }
  .footer p {
    line-height: 30px;
    margin: 0; }
  .footer li {
    width: 33.3%;
    float: left;
    line-height: 32px; }
  .footer .btn {
    border: none;
    color: #fff;
    margin: 20px 0 0; }
  .footer__heading {
    color: #091e42;
    font-weight: 900;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 0 15px; }
  .footer__copyright {
    border-top: 1px solid #d7d7d7;
    margin: 40px 0 0;
    padding: 20px 0 0; }
    .footer__copyright p {
      float: left;
      line-height: 35px; }
  .footer__sm {
    float: right; }
    .footer__sm .icon {
      color: #7f7f7f;
      font-size: 35px;
      margin-right: 8px;
      -webkit-transition: .2s ease-out;
      -o-transition: .2s ease-out;
      transition: .2s ease-out; }
      .footer__sm .icon:hover {
        color: #000; }
      .footer__sm .icon:last-of-type {
        margin-right: 0; }

.hints-data {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999; }

.hint-box {
  background: #fff;
  text-align: left;
  color: #000;
  -webkit-box-shadow: 0px 6px 5px 5px #dadada6b;
          box-shadow: 0px 6px 5px 5px #dadada6b; }
  .hint-box.active {
    display: block !important; }
  .hint-box p {
    font-size: 14px !important;
    line-height: 16px !important;
    margin: 0 !important;
    color: #000 !important;
    display: block !important; }
    .hint-box p:last-of-type {
      font-weight: 400;
      margin-top: 3px; }
    .hint-box p:first-of-type {
      font-weight: 700;
      margin-top: 0; }
    .hint-box p.no-results {
      color: #000;
      text-align: center;
      padding: 10px 15px;
      display: inline-block;
      width: 100%;
      float: none; }
  .hint-box__header {
    background: #f4f4f4;
    font-weight: 700;
    line-height: 30px;
    padding: 0 15px;
    display: inline-block;
    width: 100%;
    color: #000 !important;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    transition: .4s ease-out; }
    .hint-box__header:hover {
      background: #005691;
      color: #fff !important; }
    .hint-box__header span {
      text-transform: uppercase;
      font-weight: 900; }
  .hint-box__el {
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
    color: #000 !important;
    border-bottom: 1px solid #ccc;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    transition: .4s ease-out; }
    .hint-box__el:hover, .hint-box__el.active {
      background: #005691;
      color: #fff !important; }
      .hint-box__el:hover p, .hint-box__el.active p {
        color: #fff !important; }
      .hint-box__el:hover span, .hint-box__el.active span {
        color: #ffab00; }
      .hint-box__el:hover .hint-box__sat span, .hint-box__el.active .hint-box__sat span {
        color: #fff; }
        .hint-box__el:hover .hint-box__sat span .blue, .hint-box__el.active .hint-box__sat span .blue {
          color: #ffab00 !important; }
    .hint-box__el:last-of-type {
      border-bottom: none; }
    .hint-box__el span {
      color: #005691;
      font-weight: 900; }
  .hint-box__sat {
    margin-left: calc(10% + 10px); }
    .hint-box__sat span {
      font-weight: 700;
      color: #000; }
  .hint-box__img {
    float: left;
    margin-right: 15px;
    width: 10%;
    height: auto; }
    .hint-box__img img {
      max-width: 100%; }
  .hint-box__more {
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    text-align: center;
    line-height: 30px;
    background: #005691;
    width: 100%;
    display: inline-block;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    transition: .4s ease-out; }
    .hint-box__more:hover {
      background: #ffab00; }

/** styles **/
.error404 {
  background-image: none; }
  .error404 .breadcrumb {
    display: none; }

.page-404 {
  padding-top: 55px;
  text-align: center;
  font-size: 40.9px; }
  .page-404 h2 {
    font-size: 102px;
    margin: 15px 0; }
  .page-404 p {
    margin: 30px 0; }
  @media only screen and (min-width: 1920px) {
    .main-container--post {
      max-width: 1600px; }
      .hint-box__sat {
        margin-left: 70px; }
      .hint-box__img {
        max-width: 60px; }
  .main-container--post .hint-box__img {
    width: 60px; }
  .main-container--post .hint-box__sat {
    margin-left: 70px; } }
  @media only screen and (max-width: 1600px) {
      .main-container--bigger {
        max-width: 1200px; } }
  @media only screen and (max-width: 1300px) {
        .top-bar__search input {
          width: 255px; } }
  @media only screen and (max-width: 1230px) {
        .top-bar__search input {
          width: 200px;
          font-size: 12px; } }
  @media only screen and (max-width: 1150px) {
    .fot-blue-header {
      line-height: 40px; }
      .fot-blue-header h3 {
        font-size: 36px; }
      .top-bar__logo {
        width: 200px; }
        .top-bar__logo img {
          top: -25px; }
      .top-bar__search {
        margin-left: -35px; }
        .top-bar__search input {
          width: 190px; } }
  @media only screen and (max-width: 1065px) {
      .top-bar__nav {
        display: none !important; }
        .top-bar__nav--home {
          display: block !important; }
          .top-bar__nav--home .nav-business {
            display: none; }
          .top-bar__nav--home .top-bar__nav-el:nth-last-of-type(2) {
            display: none; } }
  @media only screen and (max-width: 991px) {
    .col--sm {
      width: 100%; }
    .sm-show {
      display: block; }
        .top-bar__logo img {
          left: -22px !important; }
      .top-bar__search {
        width: calc(100% - 450px); }
      .top-bar__search {
        margin-left: -15px; }
        .top-bar__search input {
          width: 100% !important;
          font-size: 15px; }
      .categories-menu__el {
        width: 29%;
        margin: 0 2.16%; }
    .mobile-nav {
      display: block !important; }
      .footer .btn {
        margin-bottom: 55px; }
      .footer__copyright {
        text-align: center; }
        .footer__copyright p {
          float: none;
          margin: 0 0 10px;
          line-height: 22px; }
      .footer__sm {
        float: none; }
      .page-404 h2 {
        font-size: 76px;
        margin: 0 0 10px; }
      .page-404 p {
        font-size: 30px;
        margin: 20px 0; } }
  @media only screen and (max-width: 768px) {
    * {
      word-break: break-word; }
    .col--mb {
      width: 100%; }
    .mb-hide {
      display: none; }
    .mb-show {
      display: block; }
    .fot-blue-header {
      line-height: 32px; }
      .fot-blue-header h3 {
        font-size: 30px; }
      .fot-question:before,
      .cloudrank:before {
        font-size: 42px;
        left: 15px;
        top: 18px; }
      .fot-question:after,
      .cloudrank:after {
        display: none; }
    .fot-question,
    .cloudrank {
      padding: 20px 65px; }
      .fot-question__text, .fot-question__header,
      .cloudrank__text,
      .cloudrank__header {
        font-size: 20px; }
        .fot-question__text p, .fot-question__header p,
        .cloudrank__text p,
        .cloudrank__header p {
          font-size: 20px !important; }
      .fot-big-image__img img {
        width: 100%; }
      .top-bar__nav--home {
        display: none !important; } }
  @media only screen and (max-width: 615px) {
    .top-bar .main-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
    .top-bar .pull-right {
      margin-left: auto; }
      .top-bar__search {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        margin-top: 15px;
        width: 100% !important;
        left: 0 !important;
        margin-left: 0; } }
  @media only screen and (max-width: 480px) {
      .btn--listed {
        padding-left: 26px; }
  .mini-hide {
    display: none; }
    .mini-show {
      display: block !important; }
      .fot-question:before,
      .cloudrank:before {
        display: none; }
    .fot-question,
    .cloudrank {
      padding: 20px 30px; }
      .top-bar__logo {
        width: calc(100% - 30px); }
        .top-bar__logo img {
          top: -118px;
          left: -37px;
          height: 184px;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          -webkit-transform: scale(0.7);
              -ms-transform: scale(0.7);
                  transform: scale(0.7); }
    .top-bar .main-container {
      display: block; }
    .categories-menu .btn--listed {
      width: 100%;
      margin: 20px 0 0;
      background: transparent; }
      .categories-menu .btn--listed:hover {
        color: #fff;
        background: #018eef; }
      .categories-menu__el {
        width: 100%;
        padding: 8px 0;
        margin: 0;
        line-height: 22px; }
      .categories-menu__ul {
        padding-left: 20px; }
      .categories-menu__submenu {
        padding-left: 15px;
        width: calc(100% - 15px); }
      .mobile-nav ul {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
      .mobile-nav li {
        width: 100%; }
    .footer {
      padding: 40px 0 25px;
      font-size: 14px; }
      .footer p {
        line-height: 20px;
        margin: 0 0 5px; }
      .footer li {
        width: 50%; }
      .footer .btn {
        display: none; }
      .footer__heading {
        margin-top: 25px; }
    .hint-box {
      width: calc(100% + 50px);
      font-size: 12px; }
      .hint-box p {
        font-size: 12px !important;
        line-height: 14px !important;
        float: left;
        width: calc(90% - 10px); }
      .hint-box__el {
        padding: 10px; }
      .hint-box__img {
        margin-right: 10px; }
  body:not(.home) .hint-box {
    width: 100%; } }
